<template>
    <div class="container">
        <Title titulo="Tarefas" />

        <div class="navigate">
            <div class="navigate__file">
                <label for="tarefaFile">Adicionar arquivo</label>
                <input type="file" id="tarefaFile" ref="myFiles" @change="previewFiles" accept=".xlsx" />
            </div>

            <div>
                <Button texto="Gerar arquivo exemplo" @click="downloadCSVData" />
            </div>
        </div>

        <Spinner v-if="isLoading" />

        <template v-if="Array.isArray(previewData) && previewData.length">
            <table class="preview">
                <caption>
                    Pré-visualização das tarefas
                </caption>

                <thead>
                    <tr>
                        <th>material</th>
                        <th>descrição</th>
                        <th>codigo EAN</th>
                        <th>Nó Mercadologico</th>
                        <th>Data Validade</th>
                    </tr>
                </thead>

                <tbody>
                    <template v-for="(tarefa, index) in previewData" :key="index">
                        <tr v-if="index < 20">
                            <td>{{ tarefa.sku }}</td>
                            <td>{{ tarefa.descricao }}</td>
                            <td>{{ tarefa.codigo }}</td>
                            <td>{{ tarefa.mercadologico }}</td>
                            <td>{{ tarefa.data_validade }}</td>
                        </tr>
                    </template>
                </tbody>
            </table>

            <div class="preview__desc">
                <label for="descricao">Descrição do Bloco</label>
                <input type="text" name="descricao" id="descricao" maxlength="60" v-model="descricao" />
            </div>

            <fieldset class="lojas">
                <legend>Selecione a(s) Loja(s)</legend>

                <div class="lojas__items">
                    <div v-for="loja in lojas" :key="loja" class="lojas__item">
                        <label :for="`loja_${loja}`">Loja {{ loja }}</label>

                        <input type="checkbox" :value="loja" :id="`loja_${loja}`" v-model="checkedLojas" />
                    </div>
                </div>

                <button class="lojas__select" @click="checkedLojas = lojas">Selecionar Todas</button>
                <button class="lojas__deselect" @click="checkedLojas = []">Remover Todas</button>
            </fieldset>

            <div class="saveButton">
                <Button texto="Salvar tarefas" @click="enviaRequisicao" />
            </div>
        </template>

        <div class="overlay" v-if="showOverlay">
            <div class="overlay__spinner">
                <Spinner />
            </div>
        </div>

        <Modal v-if="showModal" :isError="isError" :mensagem="modalMessage" @closeModal="showModal = false" />
    </div>
</template>

<script>
import Title from '../components/UI/Title.vue';
import Modal from '../components/Modal.vue';
import Button from '../components/UI/Button.vue';
import Spinner from '../components/UI/Spinner.vue';

export default {
    components: {
        Title,
        Modal,
        Button,
        Spinner,
    },

    data() {
        return {
            isLoading: false,
            user: null,
            headers: null,
            showModal: false,
            isError: false,
            modalMessage: '',
            previewData: [],
            filename: '',
            showOverlay: false,
            checkedLojas: [],
            lojas: [],
            descricao: '',
        };
    },

    created() {
        this.user = JSON.parse(sessionStorage.getItem('user'));
        this.headers = { Accept: 'application/json', authorization: 'Bearer ' + this.user.token };

        this.axios
            .get(`${this.user.cliente}/lojas`, { headers: this.headers })
            .then((res) => {
                res.data.map((loja) => this.lojas.push(loja.loja));
                this.checkedLojas = this.lojas;
            })
            .catch(() => {
                this.isError = true;
                this.showModal = true;
                this.modalMessage =
                    'Sua solicitação não pôde ser realizada no momento, tente novamente mais tarde, ou caso o erro persista entre em contato com o suporte.';
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    beforeRouteEnter(to, from, next) {
        const permissoes = JSON.parse(sessionStorage.getItem('permissoes'));

        if (permissoes.permissoes.tela_tarefas == 0) {
            next({ path: '/usuarios' });
        } else {
            next();
        }
    },

    methods: {
        downloadCSVData() {
            let csv = 'Material;Descricao Material;Codigo EAN;No Mercadologico;Fator Conv;Status Mat;Preco;Data Validade\n';

            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            anchor.target = '_blank';
            anchor.download = 'exemplo_das_tarefas.csv';
            anchor.click();
        },
        enviaRequisicao() {
            const data = {
                tarefas: this.previewData,
                filename: this.filename,
                lojas: this.checkedLojas,
                descricao: this.descricao,
                data_validade: this.data_validade,
            };

            this.showOverlay = true;

            this.axios
                .post(`${this.user.cliente}/tarefas-recebimento`, data, {
                    headers: this.headers,
                })
                .then((response) => {
                    this.previewData = response.data;
                    this.$refs.myFiles.value = '';

                    this.isError = false;
                    if (Array.isArray(this.previewData) && this.previewData.length) {
                        this.modalMessage =
                            'Tarefas salvas com sucesso, porém não foi possivel salvar algumas devido a um erro de formatação em uma das colunas.';
                    } else {
                        this.modalMessage = 'Tarefas Salvas com sucesso!';
                    }

                    this.checkedLojas = [];
                })
                .catch(() => {
                    this.isError = true;
                    this.modalMessage =
                        'Sua solicitação não pôde ser realizada no momento, tente novamente mais tarde, ou caso o erro persista entre em contato com o suporte.';
                })
                .finally(() => {
                    this.showModal = true;
                    this.showOverlay = false;
                });
        },

        previewFiles() {
            let bodyFormData = new FormData();

            bodyFormData.append('tarefas', this.$refs.myFiles.files[0]);
            bodyFormData.append('hasDate', true);

            this.isLoading = true;

            this.axios
                .post(`${this.user.cliente}/tarefas-xlsx`, bodyFormData, {
                    headers: this.headers,
                    'Content-Type': 'multipart/form-data',
                })
                .then((response) => {
                    this.previewData = response.data.data;
                    this.filename = response.data.filename;
                })
                .catch(() => {
                    this.isError = true;
                    this.modalMessage =
                        'Sua solicitação não pôde ser realizada no momento, tente novamente mais tarde, ou caso o erro persista entre em contato com o suporte.';
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.navigate {
    display: grid;
    gap: 10px;
    margin-bottom: 20px;
    justify-content: space-between;

    @media (min-width: 996px) {
        grid-template-columns: 1.5fr 1fr;
    }

    label {
        font-weight: bold;
        font-size: 1.5rem;
    }

    &__file {
        display: grid;
        gap: 10px;
    }
}

.usuarios {
    display: grid;
    gap: 30px;

    @media (min-width: 768px) {
        gap: 30px;
        justify-content: space-between;
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1600px) {
        gap: 50px;
        grid-template-columns: repeat(4, 1fr);
    }
}

.saveButton {
    display: grid;
    margin-top: 50px;
    margin-bottom: 30px;
    width: 35%;
}

.preview {
    border-collapse: collapse;
    width: 100%;

    caption {
        padding: 10px;
        font-weight: bold;
        font-size: 2rem;
        color: #535353;
    }

    th {
        background-color: #1c1f3d;
        color: #fff;
        padding: 10px;
        text-transform: uppercase;
        letter-spacing: 0.7px;
    }

    td {
        padding: 10px;
        text-align: center;
    }

    tr {
        border-bottom: 1px solid #1c1f3d;
        transition: 0.15s ease-in-out;

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            background-color: #1c1f3d;
            color: #fff;
        }
    }

    &__date {
        margin: 40px 0;

        label {
            display: block;
            margin-bottom: 15px;
            font-size: 1.2rem;
            font-weight: bold;
        }

        input {
            width: 250px;
            padding: 10px;
            font-size: 1.2rem;
        }
    }

    &__desc {
        margin: 40px 0;

        label {
            display: block;
            margin-bottom: 15px;
            font-size: 1.2rem;
            font-weight: bold;
        }

        input {
            width: 50%;
            padding: 10px;
            font-size: 1.2rem;
        }
    }
}

.lojas {
    padding: 20px 15px;

    legend {
        font-size: 1.2rem;
        font-weight: bold;
    }

    button {
        padding: 5px 10px;
        margin-top: 20px;
        border: none;
        color: #fff;
        border-radius: 4px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        font-size: 1rem;
    }

    &__select {
        background-color: #3fb13f;

        &:hover {
            background-color: darken($color: #3fb13f, $amount: 15);
        }
    }

    &__deselect {
        background-color: #c5463d;
        margin-left: 15px;

        &:hover {
            background-color: darken($color: #c5463d, $amount: 15);
        }
    }

    &__items {
        display: grid;
        gap: 20px;
        font-size: 1.1rem;
        grid-template-columns: repeat(5, 1fr);
    }

    &__item {
        display: flex;
        gap: 10px;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.6);

    &__spinner {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 300px;
        background: #fff;
        border-radius: 10px;
    }
}

.download_btn {
    border: none;
    width: 100%;
    display: block;
    border-radius: 5px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    color: #fff;
    transition: 0.2s ease-in-out;
    font-size: 1.1rem;
    background-color: #1e8fff;
    text-align: center;

    &:hover {
        background-color: darken($color: #1e8fff, $amount: 20);
    }
}
</style>
